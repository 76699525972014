import styled from 'react-emotion'
import { rem } from 'polished'
import theme, { media } from '../styles/theme'

export const Lead = styled.p`
  color: ${theme.colors.bodyCopy};
  font-size: ${rem('20px')};
  line-height: 1.5;
  text-align: center;
  margin: 30px 0;

  ${media.greaterThan('small')`
    font-size: ${rem('30px')};
    margin: 50px 0;
  `};
`

export default Lead
