import React from 'react'
import styled, { css } from 'react-emotion'
import { rem } from 'polished'
import theme from '../styles/theme'
import Link from './Link'

const ArticleImageContainer = styled.div`
  margin-bottom: 24px;
  padding-bottom: 59.213%;
  height: 0;
  position: relative;
`

const ArticleImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ArticleTitle = styled.h1`
  color: ${theme.colors.bodyCopy};
  font-size: ${rem('24px')};
  line-height: ${rem('33px')};
  font-weight: 800;
  text-align: center;
`

export const Article = ({ to, src, alt, title }) => (
  <Link to={to}>
    <ArticleImageContainer>
      <ArticleImage src={src} alt={alt} />
    </ArticleImageContainer>
    <ArticleTitle>{title}</ArticleTitle>
  </Link>
)

export default Article
