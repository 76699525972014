import React from 'react'
import styled, { css } from 'react-emotion'
import FloatingRuleStyle from '../styles/FloatingRuleStyle'
import theme, { media } from '../styles/theme'

export const Faq = ({ question, answer }) => (
  <dl
    className={css`
      margin: 60px 0 0;
      padding: 10px 0;
      display: grid;
      grid-gap: 20px;

      ${FloatingRuleStyle};

      ${media.greaterThan('small')`
        margin: 80px 0 40px;
        display: grid;
        grid-template-columns: 276px auto;
      `};

      &:after {
        width: 100%;
        background-color: ${theme.colors.introRule};
      }
    `}>
    <Question>{question}</Question>
    <Answer dangerouslySetInnerHTML={{ __html: answer }} />
  </dl>
)

export default Faq

export const Question = styled.dt`
  width: 276px;
  color: ${theme.colors.question};
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
`

export const Answer = styled.dd`
  color: ${theme.colors.answer};
  margin-left: 0;

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`
