import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled from 'react-emotion'
import Helmet from 'react-helmet'
import { rem } from 'polished'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import HeroContent from '../components/HeroContent'
import {
  HeroHeadingPrimary,
  HeroHeadingSecondary,
} from '../components/HeroHeadings'
import LayoutContainer from '../components/LayoutContainer'
import Section from '../components/Section'
import SectionHeading from '../components/SectionHeading'
import Article from '../components/Article'
import Faq from '../components/Faq'
import Lead from '../components/Lead'
import { media } from '../styles/theme'

const Articles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 15px;

  ${media.greaterThan('small')`
    grid-template-columns: 1fr 1fr;
  `};
`

export default ({ data, pageContext }) => {
  const page = data.page.data
  const { site } = data
  const articles = page.articles
    .map(a => a.article.document[0])
    .filter(a => !a.uid.startsWith('__'))

  return (
    <Layout>
      <Helmet>
        <title>Resources - {site.siteMetadata.title}</title>
      </Helmet>
      <Hero
        {...pageContext.heroProps}
        src={page.heroImage.url}
        alt={page.title}>
        <HeroContent>
          <HeroHeadingPrimary>{page.heroText}</HeroHeadingPrimary>
          <HeroHeadingSecondary>{page.heroSubheader}</HeroHeadingSecondary>
        </HeroContent>
      </Hero>
      <LayoutContainer>
        <Lead dangerouslySetInnerHTML={{ __html: page.introduction.html }} />
        {!!articles.length && (
          <Section>
            <SectionHeading>Useful Articles</SectionHeading>
            <Articles>
              {articles.map(article => (
                <Article
                  key={article.uid}
                  to={`/articles/${article.uid}/`}
                  src={article.data.heroImage.thumbnail.url}
                  alt={`Image for ${article.data.title}`}
                  title={article.data.title}
                />
              ))}
            </Articles>
          </Section>
        )}
        {page.faqs.length && (
          <Section>
            <SectionHeading>{page.faqsSectionHeading}</SectionHeading>
            {page.faqs.map(faq => (
              <Faq
                key={faq.question.text}
                question={faq.question.text}
                answer={faq.answer.html}
              />
            ))}
          </Section>
        )}
      </LayoutContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    page: prismicResources {
      data {
        heroText: hero_text
        heroSubheader: hero_subheader
        heroImage: hero_image {
          url
          dimensions {
            width
            height
          }
        }
        introduction {
          html
        }
        articles {
          article {
            document {
              uid
              data {
                title
                heroImage: hero_image {
                  thumbnail {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                  thumbnail_2x {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        faqsSectionHeading: faqs_section_heading
        faqs {
          question {
            text
          }
          answer {
            html
          }
        }
      }
    }
  }
`
